
import { defineComponent } from 'vue'
import FlashMessageDisplay from '@/components/FlashMessageDisplay.vue'

export default defineComponent({
  watch: {
    '$route' () {
      this.$store.commit('dismissAll')
    }
  },
  components: {
    FlashMessageDisplay: FlashMessageDisplay
  },
  methods: {
    logout () {
      this.$api.logout()
    },
    impersonateStop () {
      this.$api.impersonateStop()
    },
    async adminVerifyEmail () {
      await this.$api.adminVerifyEmail(this.impersonateEmailAddress)
    }
  },
  computed: {
    loggedIn () {
      return this.$store.getters.guardian
    },
    admin () {
      return this.$store.getters.guardian?.isAdmin
    },
    impersonateEmailAddress () {
      return this.$store.getters.impersonate
    }
  }
})
