
import { defineComponent } from 'vue'
import utils from '@/utils/utils'
import MotifClass from '@/types/class'
import financialFormatMixin from '@/utils/financialFormatMixin'
import getCourseImageUrlMixin from '@/utils/getCourseImageUrlMixin'

export default defineComponent({
  mixins: [financialFormatMixin, getCourseImageUrlMixin],
  data () {
    return {
      classes: [] as MotifClass[]
    }
  },
  async mounted () {
    // if a class has already been seelcted, redirect to enroll_students
    if (this.$store.getters.selectedClass) {
      this.$router.replace({ name: 'enroll_students' })
    }
    const response = await this.$api.getCurrentClasses()
    if (response.classes) {
      this.classes = response.classes
    }
  },
  methods: {
    dateSummary (motifClass: MotifClass) {
      return utils.getClassDateSummary(motifClass)
    },
    timeSummary (motifClass: MotifClass) {
      return utils.getClassTimeSummary(motifClass)
    },
    selectClass (motifClass: MotifClass) {
      this.$store.commit('selectClass', { class: motifClass })
      if (!this.$store.getters.guardian) {
        return this.$router.push({ name: 'login' })
      }
      this.$router.push({ name: 'enroll_students' })
    }
  }
})
