<template>
  <div class="container">
    <div class="alert alert-danger alert-dismissible mt-3" v-for="(error, id) in errors" :key="id">{{ error }} <button class="btn-close" @click="dismissError(error)"></button></div>
    <div class="alert alert-success alert-dismissible mt-3" v-for="(success, id) in successes" :key="id">{{ success }} <button class="btn-close" @click="dismissSuccess(success)"></button></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    errors (): string[] {
      return this.$store.getters.errors
    },
    successes (): string[] {
      return this.$store.getters.successes
    }
  },
  methods: {
    dismissError (error: string) {
      this.$store.commit('dismissError', { error: error })
    },
    dismissSuccess (success: string) {
      this.$store.commit('dismissSuccess', { success: success })
    }
  }
})
</script>
