import Class from '@/types/class'

export default {
  getOffset (date: Date): string {
    const offset = date.getTimezoneOffset() / 60
    const offsetPartialHours = (offset % 1)
    const offsetHours = offset - offsetPartialHours
    const offsetHourPadding = offsetHours < 10 ? '0' : ''
    const offsetMinutes = offsetPartialHours * 60
    const offsetMinutePadding = offsetMinutes < 10 ? '0' : ''
    const offsetSign = offset > 0 ? '-' : '+'
    return `${offsetSign}${offsetHourPadding}${offsetHours}:${offsetMinutePadding}${offsetMinutes}`
  },
  dateToTimeString (date: Date): string {
    const goodDateString = date.toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
    const goodTimeString = date.toLocaleTimeString('en-CA', { hour12: false, hour: '2-digit', minute: '2-digit' })
    let [hour, minute] = goodTimeString.split(':')
    if (hour === '24') {
      hour = '00'
    }
    const offset = this.getOffset(date)
    const timeString = `${goodDateString}T${hour}:${minute}:00${offset}`
    const validDateTest = new Date(timeString)
    if (isNaN(validDateTest.getTime())) {
      return ''
    }
    return timeString
  },
  timeInputValueToDate (timeString: string): Date {
    const [hours, minutes] = timeString.split(':')
    const time = new Date()
    time.setHours(parseInt(hours))
    time.setMinutes(parseInt(minutes))
    time.setSeconds(0)
    return time
  },
  timeInputValueToTimeString (timeString: string): string {
    const [hours, minutes] = timeString.split(':')
    const time = new Date()
    time.setHours(parseInt(hours))
    time.setMinutes(parseInt(minutes))
    time.setSeconds(0)
    return this.dateToTimeString(time)
  },
  dateToTimeInputValue (date: Date): string {
    return date.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
  },
  getClassDateRange (motifClass: Class): string {
    const dates = motifClass.dates
    const startDateString = dates[0].date
    const stopDateString = dates[dates.length - 1].date
    const startDate = new Date(startDateString)
    const stopDate = new Date(stopDateString)
    return startDate.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit' }) + ' - ' + stopDate.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit' })
  },
  dateInputValueToDateString (dateString: string): string {
    const offset = this.getOffset(new Date(dateString))
    const isoDateString = `${dateString}T00:00:00${offset}`
    const date = new Date(isoDateString)
    console.log(dateString, isoDateString, date)
    return this.dateToTimeString(date)
  },
  dateStringToDateInputValue (dateString: string): string {
    const date = new Date(dateString)
    const dateInputValue = date.toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
    return dateInputValue
  },
  dateStringToHumanReadable (dateString: string): string {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
  },
  weekdays: [
    'Sundays',
    'Mondays',
    'Tuesdays',
    'Wednesdays',
    'Thursdays',
    'Fridays',
    'Saturdays'
  ],
  getDateSummary (dateStrings: string[]): string {
    const weekdays: Record<string, boolean> = {}
    const formattedDateStrings: string[] = []
    dateStrings.forEach(dateString => {
      const date = new Date(dateString)
      const weekday = this.weekdays[date.getUTCDay()]
      weekdays[weekday] = true
      formattedDateStrings.push(date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' }))
    })
    return `${Object.keys(weekdays).join(', ')}: ${formattedDateStrings.join(', ')}`
  },
  getClassDateSummary (motifClass: Class): string {
    const dates = motifClass.dates
    return this.getDateSummary(dates.map(date => date.date))
  },
  getTimeSummary (startTimeString: string, stopTimeString: string): string {
    const startTime = new Date(startTimeString)
    const stopTime = new Date(stopTimeString)
    return `${startTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' })} - ${stopTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' })}`
  },
  getClassTimeSummary (motifClass: Class): string {
    return this.getTimeSummary(motifClass.startTime, motifClass.stopTime)
  }
}
