import { Router } from 'vue-router'
import { Store } from 'vuex'
import ApiResponse from '@/types/api_response'
import Course from '@/types/course'
import MotifClass from '@/types/class'
import Student from '@/types/student'
import { MyStoreState } from '@/types/store'
import Guardian from '@/types/guardian'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import Enrollment from '@/types/enrollment'
import config from '@/utils/config'

interface FetchOptions {
  method: 'GET' | 'POST'
  headers?: Record<string, string>
  body?: string
}

class Api {
  store: Store<MyStoreState>
  router: Router
  baseUrl = config.baseUrl
  stripeKey = config.stripeKey
  stripe: Stripe | null = null
  recaptchaToken: string | null = null
  constructor (store: Store<MyStoreState>, router: Router) {
    this.store = store
    this.router = router
  }

  get guardian (): Guardian | null {
    return this.store.getters.guardian
  }

  set guardian (guardian: Guardian | null) {
    this.store.commit('guardian', { guardian: guardian })
  }

  async request (route: string, data?: string): Promise<ApiResponse> {
    const options: FetchOptions = {
      method: 'GET'
    }
    if (data) {
      options.method = 'POST'
      options.body = data
    }
    // if guardian set, add basic auth header
    if (this.guardian) {
      const token = btoa(`${this.guardian.emailAddress}:${this.guardian.token}`)
      options.headers = {
        ...options.headers,
        Authorization: `Basic ${token}`
      }
    }
    // if recaptchaToken set, add captcha header
    if (this.recaptchaToken) {
      options.headers = {
        ...options.headers,
        'X-Recaptcha-Token': this.recaptchaToken
      }
    }
    // if impersonate set, add x-impersonate-user header
    if (this.store.getters.impersonate && options.headers) {
      options.headers['X-Impersonate-User'] = this.store.getters.impersonate
    }
    const response = await fetch(`${this.baseUrl}${route}`, options)
    const jsonResponse = await response.json()
    if (jsonResponse.error) {
      this.store.commit('error', jsonResponse)
    }
    if (jsonResponse.success) {
      this.store.commit('success', jsonResponse)
    }
    return jsonResponse
  }

  get (route: string): Promise<ApiResponse> {
    return this.request(route)
  }

  async post (route: string, data: string): Promise<ApiResponse> {
    const response = await this.request(route, data)
    if (response.guardian && this.guardian && response.guardian.id === this.guardian.id) {
      this.guardian = response.guardian
    }
    return response
  }

  async login (emailAddress: string, password: string): Promise<ApiResponse> {
    this.guardian = null
    const data = {
      emailAddress: emailAddress,
      password: password
    }
    const response = await this.post('/user/login', JSON.stringify(data))
    if (response.guardian) {
      this.guardian = response.guardian
      this.router.replace({ name: 'home' })
    }
    return response
  }

  async tokenLogin (guardianId: number, token: string): Promise<ApiResponse> {
    const data = {
      guardianId: guardianId,
      token: token
    }
    const response = await this.post('/user/token_login', JSON.stringify(data))
    return response
  }

  logout (): void {
    this.impersonateStop()
    window.localStorage.removeItem('guardian')
    this.guardian = null
    this.router.replace({ name: 'login' })
  }

  async verifyEmail (emailAddress: string): Promise<ApiResponse> {
    return this.post(`/user/send_verification/${encodeURIComponent(emailAddress)}`, JSON.stringify({ captchaInfo: '' }))
  }

  async forgotPassword (emailAddress: string): Promise<ApiResponse> {
    return this.post(`/user/forgot_password/${encodeURIComponent(emailAddress)}`, JSON.stringify({ captchaInfo: '' }))
  }

  async register (guardian: Guardian): Promise<ApiResponse> {
    return this.post('/user/register', JSON.stringify(guardian))
  }

  async listCourses (): Promise<ApiResponse> {
    return this.get('/courses')
  }

  async getCourse (id: number): Promise<ApiResponse> {
    return this.get(`/course/${id}`)
  }

  async createCourse (course: Course): Promise<ApiResponse> {
    return this.post('/course', JSON.stringify(course))
  }

  async updateCourse (course: Course): Promise<ApiResponse> {
    return this.post(`/course/${course.id}`, JSON.stringify(course))
  }

  async updateGuardian (guardian: Guardian): Promise<ApiResponse> {
    return this.post('/guardian', JSON.stringify(guardian))
  }

  async deleteCourse (id: number): Promise<ApiResponse> {
    return this.get(`/course/${id}/delete`)
  }

  async listClasses (): Promise<ApiResponse> {
    return this.get('/classes')
  }

  async getClass (id: number): Promise<ApiResponse> {
    return this.get(`/class/${id}`)
  }

  async createClass (motifClass: MotifClass): Promise<ApiResponse> {
    return this.post('/class', JSON.stringify(motifClass))
  }

  async updateClass (motifClass: MotifClass): Promise<ApiResponse> {
    return this.post(`/class/${motifClass.id}`, JSON.stringify(motifClass))
  }

  async deleteClass (id: number): Promise<ApiResponse> {
    return this.get(`/class/${id}/delete`)
  }

  async getCurrentClasses (): Promise<ApiResponse> {
    return this.get('/classes_current')
  }

  async getStudents (): Promise<ApiResponse> {
    return this.get('/user/students')
  }

  async getStudent (id: number): Promise<ApiResponse> {
    return this.get(`/user/student/${id}`)
  }

  async createStudent (student: Student): Promise<ApiResponse> {
    return this.post('/user/student', JSON.stringify(student))
  }

  async updateStudent (student: Student): Promise<ApiResponse> {
    return this.post(`/user/student/${student.id}`, JSON.stringify(student))
  }

  async deleteStudent (studentId: number): Promise<ApiResponse> {
    return this.get(`/user/student/${studentId}/delete`)
  }

  async enroll (classId: number, studentIds: number[], name: string, date: string): Promise<ApiResponse> {
    const body = {
      studentIds: studentIds,
      name: name,
      date: date
    }
    return this.post(`/class/${classId}/enroll`, JSON.stringify(body))
  }

  async getAccountWithCharges (): Promise<ApiResponse> {
    return this.get('/account/charges')
  }

  async getAccountEnrollments (): Promise<ApiResponse> {
    return this.get('/account/enrollments')
  }

  async getClassWithEnrollmentsAndGuardians (classId: number): Promise<ApiResponse> {
    return this.get(`/class/${classId}/enrollments`)
  }

  async getCards (): Promise<ApiResponse> {
    return this.get('/guardian/cards')
  }

  async getStripe (): Promise<Stripe | null> {
    if (!this.stripe) {
      this.stripe = await loadStripe(this.stripeKey)
    }
    return this.stripe
  }

  async charge (data: { cardId: string } | { token: string }): Promise<ApiResponse> {
    return this.post('/account/pay', JSON.stringify(data))
  }

  async setPassword (password: string): Promise<ApiResponse> {
    const response = await this.post('/user/set_password', JSON.stringify({ password: password }))
    if (response.guardian) {
      this.guardian = response.guardian
    }
    return response
  }

  async deleteCharge (chargeId: number): Promise<ApiResponse> {
    return this.get(`/charge/delete/${chargeId}`)
  }

  async getEnrollment (enrollmentId: number): Promise<ApiResponse> {
    return this.get(`/enrollment/${enrollmentId}`)
  }

  async deleteEnrollment (enrollmentId: number): Promise<ApiResponse> {
    return this.get(`/enrollment/${enrollmentId}/delete`)
  }

  async updateEnrollment (enrollment: Enrollment): Promise<ApiResponse> {
    return this.post(`/enrollment/${enrollment.id}`, JSON.stringify(enrollment))
  }

  async search (searchTerm: string): Promise<ApiResponse> {
    return this.post('/guardian/search', JSON.stringify({ searchTerm: searchTerm }))
  }

  async getAdminGuardianForStudent (studentId: number): Promise<ApiResponse> {
    return this.get(`/guardian/for-student/${studentId}`)
  }

  async getAdminGuardianForAccount (accountId: number): Promise<ApiResponse> {
    return this.get(`/guardian/for-account/${accountId}`)
  }

  async impersonateStart (input: { emailAddress?: string, studentId?: number, accountId?: number }): Promise<void> {
    if (input.emailAddress) {
      this.store.commit('impersonate', { emailAddress: input.emailAddress })
      return
    }
    let response
    if (input.studentId) {
      response = await this.getAdminGuardianForStudent(input.studentId)
    }
    if (input.accountId) {
      response = await this.getAdminGuardianForAccount(input.accountId)
    }
    if (response?.guardian) {
      this.impersonateStart({ emailAddress: response.guardian.emailAddress })
    }
  }

  impersonateStop (): void {
    this.store.commit('impersonate', { emailAddress: null })
    this.router.replace({ name: 'home' })
  }

  getPhoneNumber (): string {
    return '(971) 373-5303'
  }

  async adminVerifyEmail (emailAddress: string): Promise<ApiResponse> {
    const data = { emailAddress: emailAddress }
    return this.post('/admin/verify-email', JSON.stringify(data))
  }

  setRecaptchaToken (token: string): void {
    this.recaptchaToken = token
  }
}

export default Api
