import MotifClass from '@/types/class'
import Guardian from '@/types/guardian'
import { MyStore, MyStoreState } from '@/types/store'
import Student from '@/types/student'
import { createStore } from 'vuex'

export default createStore(<MyStore>{
  state: {
    guardian: null,
    errors: [],
    successes: [],
    selectedClass: null,
    selectedStudents: [],
    impersonate: null as string | null
  },
  getters: {
    guardian: (state: MyStoreState): Guardian | null => {
      if (!state.guardian) {
        const storedGuardianJson = window.localStorage.getItem('guardian') || ''
        let storedGuardian = null
        try {
          storedGuardian = JSON.parse(storedGuardianJson)
        } catch (e) {
          // suppress json decoding errors
        }
        if (storedGuardian) {
          state.guardian = storedGuardian
        }
      }
      return state.guardian
    },
    errors: (state: MyStoreState): string[] => {
      return state.errors.map(error => error.error)
    },
    successes: (state: MyStoreState): string[] => {
      return state.successes.map(success => success.success)
    },
    selectedClass: (state: MyStoreState): MotifClass | null => {
      return state.selectedClass
    },
    selectedStudents: (state: MyStoreState): Student[] => {
      return state.selectedStudents
    },
    impersonate: (state: MyStoreState): string | null => {
      return state.impersonate
    }
  },
  mutations: {
    guardian: (state: MyStoreState, payload: { guardian: Guardian }) => {
      state.guardian = payload.guardian
      window.localStorage.setItem('guardian', JSON.stringify(payload.guardian))
    },
    error: (state: MyStoreState, payload: {error: string, preserve?: boolean}) => {
      state.errors.push(payload)
    },
    success: (state: MyStoreState, payload: {success: string, preserve?: boolean}) => {
      state.successes.push(payload)
    },
    selectClass: (state: MyStoreState, payload: { class: MotifClass | null }) => {
      state.selectedClass = payload.class
    },
    selectStudents: (state: MyStoreState, payload: { students: Student[] }) => {
      state.selectedStudents = payload.students
    },
    impersonate: (state: MyStoreState, payload: { emailAddress: string }) => {
      state.impersonate = payload.emailAddress
    },
    dismissError: (state: MyStoreState, payload: { error: string }) => {
      state.errors = state.errors.filter(error => error.error !== payload.error)
    },
    dismissSuccess: (state: MyStoreState, payload: { success: string }) => {
      state.successes = state.successes.filter(success => success.success !== payload.success)
    },
    dismissAll: (state: MyStoreState) => {
      state.errors = state.errors.filter(error => {
        if (error.preserve) {
          error.preserve = false
          return true
        }
        return false
      })
      state.successes = state.successes.filter(success => {
        if (success.preserve) {
          success.preserve = false
          return true
        }
        return false
      })
    }
  },
  actions: {
  },
  modules: {
  }
})
