import store from '@/store'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/reset/:guardianId/:token',
    name: 'password_reset',
    component: () => import(/* webpackChunkName: "password_reset" */ '../views/PasswordReset.vue')
  },
  {
    path: '/token_login/:guardianId/:token',
    name: 'token_login',
    component: () => import(/* webpackChunkName: "token_login" */ '../views/TokenLogin.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/courses',
    name: 'admin_course_list',
    component: () => import(/* webpackChunkName: "admin_course_list" */ '../views/AdminCourseList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/course/:id',
    name: 'admin_course',
    component: () => import(/* webpackChunkName: "admin_course" */ '../views/AdminCourse.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/classes',
    name: 'admin_class_list',
    component: () => import(/* webpackChunkName: "admin_class_list" */ '../views/AdminClassList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/class/:id',
    name: 'admin_class',
    component: () => import(/* webpackChunkName: "admin_class" */ '../views/AdminClass.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/enrollment/:id',
    name: 'admin_enrollment',
    component: () => import(/* webpackChunkName: "admin_enrollment" */ '../views/AdminEnrollment.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/class/:id/enrollments',
    name: 'admin_class_enrollments',
    component: () => import(/* webpackChunkName: "admin_class_enrollments" */ '../views/AdminClassEnrollments.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/enroll',
    name: 'enroll_students',
    component: () => import(/* webpackChunkName: "enroll_students" */ '../views/EnrollStudents.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/students',
    name: 'student_list',
    component: () => import(/* webpackChunkName: "student_list" */ '../views/StudentList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/student/:id',
    name: 'student_edit',
    component: () => import(/* webpackChunkName: "student_edit" */ '../views/StudentEdit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/enroll/agree',
    name: 'enrollment_agreement',
    component: () => import(/* webpackChunkName: "enrollment_agreement" */ '../views/EnrollmentAgreement.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/charges',
    name: 'charges',
    component: () => import(/* webpackChunkName: "charges" */ '../views/Charges.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/enrollments',
    name: 'enrollments',
    component: () => import(/* webpackChunkName: "enrollments" */ '../views/Enrollments.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.guardian) {
      next({ name: 'login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
