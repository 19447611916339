
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    errors (): string[] {
      return this.$store.getters.errors
    },
    successes (): string[] {
      return this.$store.getters.successes
    }
  },
  methods: {
    dismissError (error: string) {
      this.$store.commit('dismissError', { error: error })
    },
    dismissSuccess (success: string) {
      this.$store.commit('dismissSuccess', { success: success })
    }
  }
})
