<template>
  <div id="page" class="min-vh-100">
    <div class="bg-light">
      <div class="m-3" v-if="impersonateEmailAddress">
        <div class="alert alert-info alert-dismissible">
          <div>
            <span>Impersonating {{ impersonateEmailAddress }}</span>
            <!-- TODO: if parent not verified, show verify button -->
            <button @click="adminVerifyEmail()" class="ms-3 btn btn-primary">Verify E-mail</button>
          </div>
          <button @click="impersonateStop()" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <a class="navbar-brand" href="https://motifclass.com"><img src="https://motifclass.com/wp-content/uploads/2020/08/cropped-Motif-Class-Logo-Transparent-5-87x97.png" alt="Motif Class Logo"></a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link active-class="active" class="nav-link" :to="{name: 'home'}">Home</router-link>
              </li>
              <li class="nav-item" v-if="!loggedIn">
                <router-link active-class="active" class="nav-link" :to="{name: 'login'}">Log In</router-link>
              </li>
              <li class="nav-item" v-if="admin">
                <router-link active-class="active" class="nav-link" :to="{name: 'admin'}">Admin</router-link>
              </li>
              <li class="nav-item" v-if="loggedIn">
                <router-link active-class="active" class="nav-link" :to="{ name: 'student_list' }">Students</router-link>
              </li>
              <li class="nav-item" v-if="loggedIn">
                <router-link active-class="active" class="nav-link" :to="{ name: 'enrollments' }">Enrollments</router-link>
              </li>
              <li class="nav-item" v-if="loggedIn">
                <router-link active-class="active" class="nav-link" :to="{ name: 'charges' }">Charges</router-link>
              </li>
              <li class="nav-item" v-if="loggedIn">
                <router-link active-class="active" class="nav-link" :to="{ name: 'profile' }">Profile</router-link>
              </li>
              <li class="nav-item" v-if="loggedIn">
                <a class="nav-link" href="#" @click.prevent="logout()">Log Out</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <div>
      <FlashMessageDisplay />
      <div class="mb-3">
        <router-view />
      </div>
    </div>
    <footer class="bg-light container-fluid">
      <p class="p-3 mb-0">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </p>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import FlashMessageDisplay from '@/components/FlashMessageDisplay.vue'

export default defineComponent({
  watch: {
    '$route' () {
      this.$store.commit('dismissAll')
    }
  },
  components: {
    FlashMessageDisplay: FlashMessageDisplay
  },
  methods: {
    logout () {
      this.$api.logout()
    },
    impersonateStop () {
      this.$api.impersonateStop()
    },
    async adminVerifyEmail () {
      await this.$api.adminVerifyEmail(this.impersonateEmailAddress)
    }
  },
  computed: {
    loggedIn () {
      return this.$store.getters.guardian
    },
    admin () {
      return this.$store.getters.guardian?.isAdmin
    },
    impersonateEmailAddress () {
      return this.$store.getters.impersonate
    }
  }
})
</script>

<style lang="scss">
@import 'bootstrap';
.grecaptcha-badge {
  visibility: hidden;
}
#page {
  display: grid;
  grid-template-rows: min-content auto min-content;
}
</style>
