<template>
<div class="container mt-3">
  <h4 class="mb-3">Classes</h4>
  <div class="accordion" id="classListAccordian">
    <div class="accordion-item" v-for="(motifClass, index) in classes" :key="index">
      <h2 class="accordion-header" :id="'heading'+index">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+index" aria-expanded="false" :aria-controls="'collapse'+index">
          <div class="me-3">
            <div class="d-flex align-items-start">
              <h5>{{ motifClass.courseName }} (Ages: {{ motifClass.studentAgeMinimum }}&ndash;{{ motifClass.studentAgeMaximum }}) at {{ motifClass.location }}</h5>
              <span class="ms-3 badge bg-success">{{ financialFormat(motifClass.price) }}</span>
            </div>
            <div class="text-muted">{{ dateSummary(motifClass) }}; {{ timeSummary(motifClass) }}</div>
          </div>
          <div><span class="badge bg-warning text-dark" v-if="motifClass.capacity <= motifClass.enrollment">Full</span></div>
        </button>
      </h2>
      <div :id="'collapse'+index" class="accordion-collapse collapse" :aria-labelledby="'heading'+index">
        <div class="accordion-body">
          <div class="d-flex align-items-center flex-wrap">
            <div class="me-3 mb-3 flex-shrink-0">
              <img :src="getCourseImageUrl(motifClass.courseId)" alt="Course image" class="img-thumbnail mw-100" width="200">
            </div>
            <div>
              <p>{{ motifClass.courseDescription }}</p>
              <button class="btn btn-primary" v-if="motifClass.capacity > motifClass.enrollment" @click="selectClass(motifClass)">Sign up</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import utils from '@/utils/utils'
import MotifClass from '@/types/class'
import financialFormatMixin from '@/utils/financialFormatMixin'
import getCourseImageUrlMixin from '@/utils/getCourseImageUrlMixin'

export default defineComponent({
  mixins: [financialFormatMixin, getCourseImageUrlMixin],
  data () {
    return {
      classes: [] as MotifClass[]
    }
  },
  async mounted () {
    // if a class has already been seelcted, redirect to enroll_students
    if (this.$store.getters.selectedClass) {
      this.$router.replace({ name: 'enroll_students' })
    }
    const response = await this.$api.getCurrentClasses()
    if (response.classes) {
      this.classes = response.classes
    }
  },
  methods: {
    dateSummary (motifClass: MotifClass) {
      return utils.getClassDateSummary(motifClass)
    },
    timeSummary (motifClass: MotifClass) {
      return utils.getClassTimeSummary(motifClass)
    },
    selectClass (motifClass: MotifClass) {
      this.$store.commit('selectClass', { class: motifClass })
      if (!this.$store.getters.guardian) {
        return this.$router.push({ name: 'login' })
      }
      this.$router.push({ name: 'enroll_students' })
    }
  }
})
</script>
